import { iBlogPost, BlogResponse, BlogPost } from "../types/blog";

const API_BASE_URL = "/api";

export async function getAllBlogPosts(): Promise<iBlogPost[]> {
  try {
    const response = await fetch(`${API_BASE_URL}/blog`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data: BlogResponse = await response.json();

    if (!data.posts || !Array.isArray(data.posts)) {
      console.error("Invalid data structure:", data);
      return [];
    }

    const validPosts = data.posts.filter((post) => {
      if (!post.metadata || !post.slug || !post.content) {
        console.warn("Invalid post structure:", post);
        return false;
      }
      return true;
    });

    if (data.errors) {
      Object.entries(data.errors).forEach(([key, value]) => {
        console.warn(`Error in file ${key}: ${value}`);
      });
    }

    return validPosts;
  } catch (error) {
    console.error("Error fetching blog posts:", error);
    return [];
  }
}

export async function getBlogPost(slug: string): Promise<BlogPost | null> {
  try {
    const response = await fetch(`${API_BASE_URL}/blog/${slug}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data: BlogPost = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching blog post with slug ${slug}:`, error);
    return null;
  }
}

export async function searchBlogPosts(query: string): Promise<iBlogPost[]> {
  try {
    const allPosts = await getAllBlogPosts();
    return allPosts.filter(
      (post) =>
        post.metadata.title.toLowerCase().includes(query.toLowerCase()) ||
        post.metadata.summary.toLowerCase().includes(query.toLowerCase()) ||
        post.metadata.tags.some((tag) => tag.toLowerCase().includes(query.toLowerCase()))
    );
  } catch (error) {
    console.error("Error searching blog posts:", error);
    return [];
  }
}

export async function getBlogPostsByTag(tag: string): Promise<iBlogPost[]> {
  try {
    const allPosts = await getAllBlogPosts();
    return allPosts.filter((post) => post.metadata.tags.includes(tag));
  } catch (error) {
    console.error(`Error fetching blog posts with tag ${tag}:`, error);
    return [];
  }
}

export function sortBlogPosts(posts: iBlogPost[]): iBlogPost[] {
  return posts.sort(
    (a, b) =>
      new Date(b.metadata.publishedAt).getTime() - new Date(a.metadata.publishedAt).getTime()
  );
}
