import { twMerge } from "tailwind-merge";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const GLOW_CONFIG = {
  HOVER_RADIUS: "60rem",
  HOVER_OPACITY: 0.2,
  HOVER_SPREAD: "15%",
  CARD_HOVER_RADIUS: "20rem",
  CARD_HOVER_OPACITY: 0.1,
  CARD_HOVER_SPREAD: "55%",
  GLOW_COLOR: "7, 110, 237",
  SCALE_FACTOR: 0.97,
  TRANSITION_DURATION: "0.15s",
  SPACING: "0.09rem"
};

interface TechIconProps {
  href: string;
  icon: any;
  name?: string;
  useSvgSprite?: boolean;
}

const TechIcon = ({ href, icon: Icon, name, useSvgSprite }: TechIconProps) => (
  <Link
    to={href}
    className="tech-card group relative flex overflow-hidden rounded transition-all duration-150"
  >
    <div className="tech-card-content z-10 flex w-full rounded ">
      <div className="flex items-center justify-between px-6 py-2 border border-dashed border-white rounded-md border-opacity-15">
        <div className="flex flex-row items-center gap-2 font-bold text-neutral-300">
          {useSvgSprite && name ? (
            <svg width="35" height="27" role="img" aria-label={`${name} logo`}>
              <use href={`/sprite.svg#${name.toLowerCase()}`} />
            </svg>
          ) : (
            <Icon />
          )}
          {name && <p className="text-[0.9rem]">{name}</p>}
        </div>
      </div>
    </div>
  </Link>
);

const TechIconsSection = ({ icons, rootClass }: { icons: any; rootClass?: string }) => {
  useEffect(() => {
    const cards = document.querySelectorAll(".tech-card");
    const wrapper = document.querySelector(".tech-cards");

    const handleMouseMove = (event: MouseEvent) => {
      cards.forEach((card) => {
        const htmlCard = card as HTMLElement;
        const rect = htmlCard.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        htmlCard.style.setProperty("--xPos", `${x}px`);
        htmlCard.style.setProperty("--yPos", `${y}px`);
      });
    };

    if (wrapper) {
      wrapper.addEventListener("mousemove", handleMouseMove as EventListener);
      return () => wrapper.removeEventListener("mousemove", handleMouseMove as EventListener);
    }
  }, [icons]);

  return (
    <>
      <div className={twMerge("tech-cards mb-8 flex flex-wrap gap-1 gap-y-2", rootClass)}>
        {icons.map((icon: any, index: number) => (
          <TechIcon key={index} {...icon} />
        ))}
      </div>

      <style>{`
        .tech-cards:hover .tech-card {
          background: radial-gradient(
            ${GLOW_CONFIG.HOVER_RADIUS} circle at var(--xPos) var(--yPos),
            rgba(${GLOW_CONFIG.GLOW_COLOR}, ${GLOW_CONFIG.HOVER_OPACITY}),
            transparent ${GLOW_CONFIG.HOVER_SPREAD}
          );
        }

        .tech-card {
          background: radial-gradient(
            150rem circle at 0 0,
            rgba(${GLOW_CONFIG.GLOW_COLOR}, 0),
            transparent 0%
          );
        }

        .tech-card:hover {
          transform: scale(${GLOW_CONFIG.SCALE_FACTOR});
        }

        .tech-card:hover::before {
          opacity: 1;
        }

        .tech-card::before {
          content: "";
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: inherit;
          background: radial-gradient(
            ${GLOW_CONFIG.CARD_HOVER_RADIUS} circle at var(--xPos) var(--yPos),
            rgba(${GLOW_CONFIG.GLOW_COLOR}, ${GLOW_CONFIG.CARD_HOVER_OPACITY}),
            transparent ${GLOW_CONFIG.CARD_HOVER_SPREAD}
          );
          opacity: 0;
          transition: all ${GLOW_CONFIG.TRANSITION_DURATION} ease-in-out;
        }

        .tech-card-content {
          height: calc(100% - ${GLOW_CONFIG.SPACING});
          width: calc(100% - ${GLOW_CONFIG.SPACING});
          margin: ${GLOW_CONFIG.SPACING};
          transition: all 0.25s;
        }
      `}</style>
    </>
  );
};

export default TechIconsSection;