import React from "react";
import { withDefault } from "../../layouts/Default";
import { FaLaptop, FaCode, FaHeadphones, FaMobileAlt } from "react-icons/fa";

const metadata = {
  title: "My Tech Arsenal",
  description: "Explore the cutting-edge tech powering my development and daily digital life.",
};

function UsesPage() {
  return (
    <section className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-primary">My Tech Arsenal</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <TechCard title="Workstations" icon={<FaLaptop />}>
          <ul className="space-y-2">
            <TechItem badge="Mac">MacBook Air M1 (2020) - 8GB RAM</TechItem>
            <TechItem badge="PC">
              Gigabyte GK KF5 - Intel i7-12650H, 64GB DDR5, NVIDIA RTX 4060 8GB
            </TechItem>
          </ul>
        </TechCard>

        <TechCard title="Dev Environment" icon={<FaCode />}>
          <ul className="space-y-2">
            <TechItem badge="Editor">VS Code with Moonlight theme</TechItem>
            <TechItem badge="Terminal">WSL (Windows) | iTerm2 with Powerlevel10k (Mac)</TechItem>
            <TechItem badge="Browser">Google Chrome</TechItem>
            <TechItem badge="Notes">Notion, Sticky Notes, New Tab Notes extension</TechItem>
          </ul>
        </TechCard>

        <TechCard title="Audio Setup" icon={<FaHeadphones />}>
          <ul className="space-y-2">
            <TechItem badge="Headset">SteelSeries Arctis Nova 7 Wireless</TechItem>
            <TechItem badge="Speakers">Logitech Z207 Bluetooth</TechItem>
          </ul>
        </TechCard>

        <TechCard title="Mobile Devices" icon={<FaMobileAlt />}>
          <ul className="space-y-2">
            <TechItem badge="iPhone">iPhone 13 Pro</TechItem>
            <TechItem badge="Android">Google Pixel 3</TechItem>
            <TechItem badge="Tablet">Samsung Galaxy Tab S9 FE</TechItem>
          </ul>
        </TechCard>
      </div>
    </section>
  );
}

interface TechCardProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const TechCard: React.FC<TechCardProps> = ({ title, icon, children }) => (
  <div className="card bg-base-100 shadow-xl">
    <div className="card-body">
      <h2 className="card-title text-2xl font-semibold mb-4 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </h2>
      {children}
    </div>
  </div>
);

interface TechItemProps {
  badge: string;
  children: React.ReactNode;
}

const TechItem: React.FC<TechItemProps> = ({ badge, children }) => {
  const badgeColor = getBadgeColor(badge);
  return (
    <li className="grid grid-cols-[auto,1fr] gap-2 items-center">
      <span
        className={`badge badge-${badgeColor} bg-opacity-50 text-white border-none font-normal w-20 justify-start pl-3 pt-[0.8rem] pb-[0.8rem]`}
      >
        {badge}
      </span>
      <span>{children}</span>
    </li>
  );
};

function getBadgeColor(badge: string): string {
  switch (badge.toLowerCase()) {
    case "mac":
    case "iphone":
      return "primary";
    case "pc":
    case "android":
      return "secondary";
    case "tablet":
    case "browser":
      return "accent";
    case "terminal":
      return "info";
    case "editor":
    case "speakers":
      return "success";
    case "notes":
      return "warning";
    case "headset":
      return "error";
    default:
      return "neutral";
  }
}

export default withDefault(<UsesPage />, metadata);
