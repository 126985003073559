import React, { useState, useCallback } from "react";

interface SearchProps {
  search?: string | null;
  handleSearch: (searchTerm: string) => void;
}

export function Search({ search, handleSearch }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState(search ?? "");

  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: Parameters<F>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedHandleSearch = useCallback(
    debounce((value: string) => {
      handleSearch(value);
    }, 50),
    [handleSearch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedHandleSearch(value);
  };

  return (
    <input
      type="text"
      className="text-md mt-[-1.5rem] w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 transition-all duration-300 ease-in-out focus:border-transparent focus:ring-1 focus:ring-blue-800 dark:border-gray-700"
      style={{
        outline: "1px solid transparent",
      }}
      placeholder="Search for a blog post..."
      value={searchTerm}
      onChange={onChange}
    />
  );
}
