import { CiLocationOn } from "react-icons/ci";

import { withDefault } from "../../layouts/Default";
import TechIconsSection from "./TechIcons";
import {
  SiArduino,
  SiTableau,
  SiPython,
  SiCplusplus,
  SiReact,
  SiRailway,
  SiNodedotjs,
  SiBun,
  SiAngular,
  SiAnsible,
  SiCss3,
  SiFlutter,
  SiHugo,
  SiLinux,
  SiTerraform,
} from "react-icons/si";
import { Metadata } from "../../types/types";

const metadata: Metadata = {
  title: "Home",
  description: "Suraj Mandal's personal website, showcasing his work and projects.",
};

function Home() {
  const techIcons = [
    { href: "/blog?tag=arduino", icon: SiArduino, name: "Arduino" },
    { href: "/blog?tag=python", icon: SiPython, name: "Python" },
    { href: "/blog?tag=tableau", icon: SiTableau, name: "Tableau" },
    { href: "/blog?tag=react", icon: SiReact, name: "React" },
    { href: "/blog?tag=nodejs", icon: SiNodedotjs, name: "Node.js" },
    { href: "/blog?tag=cpp", icon: SiCplusplus, name: "C++" },
    { href: "/blog?tag=railway", icon: SiRailway, name: "Railway" },
    { href: "/blog?tag=angular", icon: SiAngular, name: "Angular" },
    { href: "/blog?tag=flutter", icon: SiFlutter, name: "Flutter" },
    { href: "/blog?tag=terraform", icon: SiTerraform, name: "Terraform" },
    { href: "/blog?tag=ansible", icon: SiAnsible, name: "Ansible" },
    { href: "/blog?tag=linux", icon: SiLinux, name: "Linux" },
    { href: "/blog?tag=css", icon: SiCss3, name: "CSS" },
    { href: "/blog?tag=hugo", icon: SiHugo, name: "Hugo" },
    { href: "/blog?tag=bun", icon: SiBun, name: "Bun" }
  ];

  return (
    <section>
      <h1 className="mb-4 text-2xl tracking-tighter">Greetings, I'm Suraj Mandal</h1>
      <h3 className="font-medium dark:font-normal mb-2 flex text-slate-700 dark:text-gray-300">
        Full Stack Developer based in Canada{" "}
        <CiLocationOn className="ml-2 mt-[0.15rem] opacity-100" />
      </h3>
      <p className="prose-md text-slate-700 dark:prose-invert dark:text-[#d6d3d1dd] leading-relaxed">
        Creating next-gen tech: blending full-stack development, IoT, and cloud computing to turn
        complex ideas into impactful solutions. From developing real-time voice cloning apps to
        optimizing flight paths with Arduino & Openlog, I thrive on pushing technological
        boundaries. Currently pursuing Business Intelligence & System Infrastructure, I'm seeking
        co-op opportunities in Canada for Summer 2024 to apply my diverse skills in impactful
        projects.
      </p>
      <ul className="font-sm mt-5 flex flex-col space-x-0 space-y-2 text-neutral-600 md:flex-row md:space-x-6 md:space-y-0 dark:text-neutral-300">
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            target="_blank"
            href="https://github.com/surajmandalcell"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Github</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            target="_blank"
            href="https://linkedin.com/in/mandalsuraj"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Linkedin</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            href="/work"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Work History</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            href="/blog?tag=project"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">My Projects</p>
          </a>
        </li>
      </ul>

      <TechIconsSection icons={techIcons} rootClass="mt-6" />
    </section>
  );
}

function ArrowIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default withDefault(<Home />, metadata);
