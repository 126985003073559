import { withDefault } from "../layouts/Default";

function Loading() {
  return (
    <main className="flex flex-col items-center justify-center min-h-[50vh] rounded-lg">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 text-gray-800 dark:text-gray-200 animate-pulse">
          Loading..
        </h1>
        <p className="text-lg mb-6 text-gray-600 dark:text-gray-400">
          <i>Please wait while we fetch the content</i>
        </p>
      </div>
    </main>
  );
}

export default Loading;

export const LoadingWithDefault = withDefault(<Loading />);
