import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronRight, FaChevronDown } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

interface FilterProps {
  allTags: string[];
  selectedTag?: string;
}

export function Filter({ allTags, selectedTag }: FilterProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterExpanded, setFilterExpanded] = useState(false);

  function handleClick(tag: string) {
    const searchParams = new URLSearchParams(location.search);
    if (tag) {
      searchParams.set("tag", tag);
    } else if (selectedTag) {
      searchParams.set("tag", selectedTag);
    } else {
      searchParams.delete("tag");
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);
  }

  function handleClear() {
    navigate(location.pathname);
  }

  function AllTagsSliced({
    allTags,
    handleClick,
    slice,
    className,
  }: {
    allTags: string[];
    handleClick: (tag: string) => void;
    slice: { start: number; end?: number };
    className?: string;
  }) {
    const _alltags = slice.end ? allTags.slice(slice.start, slice.end) : allTags.slice(slice.start);
    return (
      <>
        {_alltags.map((tag, index) => (
          <span
            key={index}
            className={twMerge(pillStyle, className)}
            onClick={() => handleClick(tag)}
          >
            {tag.charAt(0).toUpperCase() + tag.slice(1)}
          </span>
        ))}
      </>
    );
  }

  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get("tag");

  return (
    <div
      id="tag-filters"
      className="max-w-screen mb-4 flex flex-col gap-2 opacity-80 dark:opacity-100"
    >
      <h3 className="mb-2 text-sm font-semibold">
        Filter by Tag:{" "}
        {tag && (
          <span className={`${pillStyle} ml-2 font-light`} onClick={handleClear}>
            Reset Filters &#x2715;
          </span>
        )}
      </h3>
      <details open={filterExpanded} onToggle={() => setFilterExpanded(!filterExpanded)}>
        <summary className="gapY-1 flex select-none flex-wrap items-center justify-start gap-y-2 hover:cursor-pointer">
          {filterExpanded ? (
            <FaChevronDown className="opacity-60" />
          ) : (
            <FaChevronRight className="opacity-60" />
          )}
          <AllTagsSliced
            allTags={allTags}
            handleClick={handleClick}
            slice={{ start: 0, end: 9 }}
            className="ml-1"
          />
        </summary>
        <div className="mt-1 flex flex-wrap gap-1">
          <AllTagsSliced allTags={allTags} handleClick={handleClick} slice={{ start: 9 }} />
        </div>
      </details>
    </div>
  );
}

const pillStyle =
  "cursor-pointer rounded-[3px] bg-neutral-600 px-2 py-1 text-xs text-white hover:bg-slate-800 dark:bg-zinc-800 dark:text-neutral-300 dark:hover:bg-zinc-500";
