import React, { Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import { MDXProvider } from "@mdx-js/react";
import { getBlogPost } from "../../utils/blog";
import { BlogPost as BlogPostType } from "../../types/blog";
import { withDefault } from "../../layouts/Default";

import SyntaxHighlighter, { PrismLight } from "react-syntax-highlighter";
// import { tomorrow } from "react-syntax-highlighter/dist/cjs/styles/prism";
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';

function formatDate(date: string) {
  const currentDate = new Date();
  const targetDate = new Date(date);
  const yearsAgo = currentDate.getFullYear() - targetDate.getFullYear();
  const monthsAgo = currentDate.getMonth() - targetDate.getMonth();
  const daysAgo = currentDate.getDate() - targetDate.getDate();

  let formattedDate = "";
  if (yearsAgo > 0) {
    formattedDate = `${yearsAgo}y ago`;
  } else if (monthsAgo > 0) {
    formattedDate = `${monthsAgo}mo ago`;
  } else if (daysAgo > 0) {
    formattedDate = `${daysAgo}d ago`;
  } else {
    formattedDate = "Today";
  }

  const fullDate = targetDate.toLocaleString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return `${fullDate} (${formattedDate})`;
}

const components = {
  code: ({ className, ...props }: any) => {
    console.log(className, props);
    const match = /language-(\w+)/.exec(className || "");

    PrismLight.registerLanguage('sql', sql);

    return match ? (
      <PrismLight language={match[1]} PreTag="div" {...props} />
    ) : (
      <code className={className} {...props} />
    );
  },
  // You can add more custom components here if needed
};

function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  const {
    data: post,
    isLoading,
    error,
  } = useQuery<BlogPostType | null>({
    queryKey: ["blogPost", slug],
    queryFn: () => getBlogPost(slug || ""),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading blog post</div>;
  if (!post) {
    navigate("/404");
    return null;
  }

  const { title, publishedAt, summary, tags } = post.metadata;

  // Dynamically import the MDX content
  const MDXContent = React.lazy(() => import(`../../content/${slug}.mdx`));

  return (
    <section>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={summary} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={summary} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://mandalsuraj.com/blog/${slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={summary} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: title,
            datePublished: publishedAt,
            dateModified: publishedAt,
            description: summary,
            url: `https://mandalsuraj.com/blog/${slug}`,
            author: {
              "@type": "Person",
              name: "Suraj Mandal",
            },
          })}
        </script>
      </Helmet>

      <h1 className="title max-w-[650px] text-2xl font-medium tracking-tighter">{title}</h1>

      <div className="mb-8 mt-2 flex items-center justify-between text-sm">
        <Suspense fallback={<p className="h-5" />}>
          <p className="text-sm text-neutral-600 dark:text-neutral-400">
            {formatDate(publishedAt)}
          </p>
        </Suspense>
      </div>

      <article className="prose prose-neutral prose-quoteless text-gray-700 dark:prose-invert dark:text-neutral-300">
        <MDXProvider components={components}>
          <Suspense fallback={<div>Loading content...</div>}>
            <MDXContent />
          </Suspense>
        </MDXProvider>
      </article>

      <div className="mt-8 max-w-full overflow-hidden flex flex-wrap gap-x-1 gap-y-2">
        {tags.map((tag) => (
          <span
            key={tag}
            onClick={() => navigate(`/blog?tag=${tag}`)}
            className="rounded bg-gray-200 px-2 py-1 text-sm text-gray-700 dark:bg-neutral-700 dark:text-neutral-300 hover:bg-gray-300 dark:hover:bg-neutral-600 cursor-pointer"
          >
            {tag}
          </span>
        ))}
      </div>
    </section>
  );
}

export default withDefault(<BlogPost />);
