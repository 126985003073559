import { Link } from "react-router-dom";
import { BlogPost } from "../../types/blog";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";

const GLOW_CONFIG = {
  HOVER_RADIUS: "120rem",
  HOVER_OPACITY: 0.4,
  HOVER_SPREAD: "15%",
  CARD_HOVER_RADIUS: "60rem",
  CARD_HOVER_OPACITY: 0.1,
  CARD_HOVER_SPREAD: "35%",
  GLOW_COLOR: "7, 110, 237",
  SCALE_FACTOR: 0.97,
  TRANSITION_DURATION: "0.15s",
  SPACING: "0.09rem",
};

interface RenderProps {
  filteredBlogs: BlogPost[];
  className?: string;
}

export function Render({ filteredBlogs, className }: RenderProps) {
  useEffect(() => {
    const cards = document.querySelectorAll(".card");
    const wrapper = document.querySelector(".cards");

    const handleMouseMove = (event: MouseEvent) => {
      cards.forEach((card) => {
        const htmlCard = card as HTMLElement;
        const rect = htmlCard.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        htmlCard.style.setProperty("--xPos", `${x}px`);
        htmlCard.style.setProperty("--yPos", `${y}px`);
      });
    };

    if (wrapper) {
      wrapper.addEventListener("mousemove", handleMouseMove as EventListener);
      return () => wrapper.removeEventListener("mousemove", handleMouseMove as EventListener);
    }
  }, [filteredBlogs]);

  return (
    <>
      <div
        className={twMerge(
          "cards grid grid-cols-1 gap-4 auto-rows-fr sm:grid-cols-2 lg:grid-cols-3",
          className
        )}
      >
        {filteredBlogs.map((post) => (
          <Link
            key={post.slug}
            to={`/blog/${post.slug}`}
            className="card group relative flex flex-col overflow-hidden rounded-lg transition-all duration-150"
          >
            <div className="card-content z-10 flex h-full w-full flex-col rounded-lg bg-[#171717de] min-h-[290px]">
              <div className="flex flex-col flex-1 p-6">
                <h2 className="mb-2 text-xl font-semibold text-gray-100 group-hover:text-blue-400">
                  {post.metadata.title}
                </h2>
                <p className="mb-4 text-sm text-gray-300">{post.metadata.summary}</p>
              </div>
              <div className="mt-auto px-6 py-4">
                <p className="text-sm text-gray-300">
                  {new Date(post.metadata.publishedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
                <div
                  className="mt-4 flex flex-nowrap gap-2 overflow-x-auto pb-2 hide-scrollbar"
                  tabIndex={0}
                >
                  {post.metadata.tags.slice(0, 2).map((tag) => (
                    <Pill key={tag} tag={tag} />
                  ))}
                  <Pill tag="..." />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <style>{`
        .cards:hover .card {
          background: radial-gradient(
            ${GLOW_CONFIG.HOVER_RADIUS} circle at var(--xPos) var(--yPos),
            rgba(${GLOW_CONFIG.GLOW_COLOR}, ${GLOW_CONFIG.HOVER_OPACITY}),
            transparent ${GLOW_CONFIG.HOVER_SPREAD}
          );
        }

        .card {
          background: radial-gradient(
            150rem circle at 0 0,
            rgba(${GLOW_CONFIG.GLOW_COLOR}, 0),
            transparent 0%
          );
        }

        .card:hover {
          transform: scale(${GLOW_CONFIG.SCALE_FACTOR});
        }

        .card:hover::before {
          opacity: 1;
        }

        .card::before {
          content: "";
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: inherit;
          background: radial-gradient(
            ${GLOW_CONFIG.CARD_HOVER_RADIUS} circle at var(--xPos) var(--yPos),
            rgba(${GLOW_CONFIG.GLOW_COLOR}, ${GLOW_CONFIG.CARD_HOVER_OPACITY}),
            transparent ${GLOW_CONFIG.CARD_HOVER_SPREAD}
          );
          opacity: 0;
          transition: all ${GLOW_CONFIG.TRANSITION_DURATION} ease-in-out;
        }

        .card-content {
          height: calc(100% - ${GLOW_CONFIG.SPACING});
          width: calc(100% - ${GLOW_CONFIG.SPACING});
          margin: ${GLOW_CONFIG.SPACING};
          transition: all 0.25s;
        }
      `}</style>
    </>
  );
}

function Pill({ tag }: { tag: string }) {
  return (
    <span className="whitespace-nowrap rounded-full bg-[#1e2127] px-3 py-1 text-xs font-medium text-gray-300">
      {tag}
    </span>
  );
}
